import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import newsBg from "../images/news_bg.jpg"
import tw, { styled, css } from "twin.macro"
import { FaMapMarkerAlt } from "react-icons/fa"
import { StaticImage } from "gatsby-plugin-image"
import AboutUsBg from "../images/about_us_bg.png"
import SectionHeader from "../components/SectionHeader"
import { Link, useTranslation, Trans } from "gatsby-plugin-react-i18next"
import { FaInstagram } from "react-icons/fa"
import { BsArrowRightShort } from "react-icons/bs"
import { motion, useViewportScroll, useTransform } from "framer-motion"
import { InView } from "react-intersection-observer"
import HeroBg from "../images/heroLow.png"
import heroText from "../images/hero_text.svg"
import { graphql } from "gatsby"
import arrowImg from "../images/arrow.webp"
import VideoDesktop from '../images/video_dekstop.mp4';
import VideoMobile from '../images/video_mobile.mp4';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/scrollbar";


export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index", "contact"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const App = ({ data }) => {
  const { scrollYProgress } = useViewportScroll();
  const { t } = useTranslation();
  const yOffset = useTransform(scrollYProgress, [0, 1], ["100%", "-15%"]);
  const isBrowser = typeof window !== "undefined";
  const [isMobileVideo, setIsMobileVideo] = useState(isBrowser ? window.innerWidth <= 768 : false);
  const [isSeoOpen, setIsSeoOpen] = useState(false);
  const toggleOpen = () => setIsSeoOpen(!isSeoOpen);


  useEffect(() => {
    if (!isBrowser) return;

    const handleResize = () => setIsMobileVideo(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, [isBrowser]);


  // is mobile
  const [width, setWidth] = useState(typeof window !== "undefined" && typeof window.innerWidth !== undefined ? window.innerWidth : 0);

  const isMobile = width <= 1024;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  // WP
  const [postData, setPostData] = useState([]);

  const requestURL = 'https://blog.nemezink.pl/wp-json/wp/v2/posts?_embed';

  const getPosts = async () => {
    try {
      const response = await fetch(requestURL);
      const data = await response.json();

      parsePostData(data);
    } catch (e) {
      console.error(e);
    }
  }

  const parsePostData = (data) => {
    const posts = [];

    data.forEach(postData => {
      const img = postData._embedded['wp:featuredmedia'] && postData._embedded['wp:featuredmedia'][0] ?
        postData?._embedded['wp:featuredmedia']['0']?.source_url : undefined;

      posts.push({
        title: postData.title.rendered.replace(/&#8211;/g, '-'),
        date: new Date(postData.date).toLocaleString('en-US').split(',')[0],
        imgSrc: img,
        link: postData.link
      });
    });
    setPostData(posts);
  }


  useEffect(() => {
    getPosts();

    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  return (
    <Layout>
      <HeroScreen>
        <div tw="relative">
          <HeroText
            alt={`Nemezink Hero Text`}
            src={heroText}
            animate={{
              opacity: [0, 0.4],
            }}
            transition={{ duration: 0.6 }}
          />
          <Video
            autoPlay
            loop
            muted
            playsInline
            poster
            className="video"
            key={isMobileVideo ? 'mobile' : 'desktop'} // Adding key to force re-mount when video source changes
          >
            <source src={isMobileVideo ? VideoMobile : VideoDesktop} type="video/mp4" />
          </Video>
        </div>
        <div tw="flex flex-col items-center gap-2 lg:gap-4">
          <HeroHeader><Trans>studioHeroHeader</Trans></HeroHeader>
          <Link to="/contact">
            <HeroButton>
              <Trans>heroButton</Trans>
              <RightArrow />
            </HeroButton>
          </Link>
        </div>
        <HeroDetails>
          <FaMapMarkerAlt style={{ marginRight: "10px" }} />
          <a
            href="https://goo.gl/maps/BNZFQTTdamiv3rF96"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            <Trans>location</Trans>
          </a>
        </HeroDetails>
      </HeroScreen>
      <AboutUsSection>
        <AboutUsLeft>
          <InView triggerOnce={true} rootMargin={`-20%`}>
            {({ ref, inView }) => (
              <motion.div
                ref={ref}
                animate={{ x: inView ? "0%" : "-50%", opacity: inView ? 1 : 0 }}
                transition={{ duration: 0.5, ease: "easeOut" }}
              >
                <SectionHeader
                  tag={<Trans>studioTag</Trans>}
                  text={<Trans>studioHeader</Trans>}
                  emphasizedText={<Trans>studioHeaderSub</Trans>}
                  size="lg"
                />
                <DesktopText>
                  <Trans>studioIntro</Trans>
                </DesktopText>
              </motion.div>
            )}
          </InView>
          <StaticImage
            src="../images/AboutUsAtmosphere.jpg"
            alt="dwie uśmiechnięte dziewczyny w salonie tatuażu w Krakowie Nemezink"
            quality={20}
            css={css`
              width: 31.25vw;
              height: 41.6vw;
              margin-top: 1vw;
              @media (max-width: 1024px) {
                margin: 0vw 6vw 0vw 0vw;
                min-width: 42vw;
                min-height: 62vw;
              }
            `}
          />
          <StudioValue
            index="02"
            value={<Trans>studioSecondValue</Trans>}
            description={<Trans>studioSecondDesc</Trans>}
            animationDirection="-50%"
          />
          <StaticImage
            src="../images/aboutUsPro.jpg"
            alt="tatuażystka wykonuje tatuaż klientowi w krakowskim salonie tatuażu Nemezink"
            quality={50}
            css={css`
              width: 31.25vw;
              height: 37vw;
              @media (max-width: 1024px) {
                width: 45vw;
                height: 53vw;
              }
            `}
          />
          {/* // 19.05.2022 remove two last paragraphs
          <StudioValue
            index="04"
            value="Estetyka"
            description="Wiemy, że nasza praca zostanie z Tobą na całe życie. To dla nas ogromna motywacja, by każdego dnia pielęgnować tak umiejętności, jak i poczucie estetyki. Dlatego każda z nas ma swoją specjalizację!"
            animationDirection="-50%"
          />
          <StaticImage
            src="../images/aboutUsSafety.jpg"
            alt="Nemezink artist Owca tattoing customer at nemezink studio"
            quality="80"
            css={css`
              width: 31.25vw;
              height: 35vw;
              max-height: 35vw;
              @media (max-width: 1024px) {
                width: 45vw;
                height: 53vw;
              }
            `}
          /> */}
        </AboutUsLeft>
        <AboutUsRight>
          <StaticImage
            src="../images/team.png"
            alt="Zespół tatuażystek krakowiskiego studia tatuażu Nemezink"
            quality={30}
            css={css`
              width: 34vw;
              height: 44vw;
              @media (max-width: 1024px) {
                min-width: 58vw;
                min-height: 78vw;
                margin-top: 6vw;
              }
            `}
          />
          <StudioValue
            index="01"
            value={<Trans>studioFirstValue</Trans>}
            description={<Trans>studioFirstDesc</Trans>}
            animationDirection={"50%"}
          />
          <StaticImage
            src="../images/aboutUsCreativity.jpg"
            alt="skończony tatuaż na przedramieniu wykonany w studiu tatuażu w Krakowie "
            css={css`
              width: 34vw;
              height: 32.2vw;
            `}
          />
          <StudioValue
            index="03"
            value={<Trans>studioThirdValue</Trans>}
            description={
              <Trans>studioThirdDesc</Trans>
            }
            animationDirection="50%"
          />
          {/* <StaticImage
            src="../images/aboutUsAesthetics.jpg"
            alt="finishing toches on a tattoo of a sun"
            css={css`
              width: 34vw;
              height: 32.2vw;
            `}
          />
          <StudioValue
            index="05"
            value="Bezpieczeństwo"
            description={
              <span>
                Bezpieczeństwo przede wszystkim! W trosce o Twoje zdrowie i
                komfort – do każdego tatuażu używamy nowych igieł, czyścimy
                miejsce pracy oraz dbamy o stan wszystkiego, co znajduje się w
                studio.
              </span>
            }
            animationDirection="50%"
          /> */}
        </AboutUsRight>
        <MobileAboutUs>
          <SectionHeader
            tag={<Trans>studioTag</Trans>}
            text={<Trans>studioHeader</Trans>}
            emphasizedText={<Trans>studioHeaderSub</Trans>}
          />
          <MobileText>
            <Trans>studioIntro</Trans>
          </MobileText>
          <Gallery />
        </MobileAboutUs>
      </AboutUsSection>
      <PromoSection>
        <div tw="flex flex-row justify-between items-center">
          <VoucherHeader>
            <Trans>voucherHeader</Trans>
            <p style={{ fontFamily: "Merriweather" }}><Trans>voucherHeaderSub</Trans></p>
          </VoucherHeader>
          <div tw="flex flex-col justify-center items-start">
            {/* <VoucherSubheader>VOUCHERY</VoucherSubheader> */}
            <VoucherText>
              <Trans>voucherDesc</Trans>
            </VoucherText>
          </div>
          <div tw="flex flex-col items-center gap-4">
            <Link to="/contact">
              <StaticImage
                src="../images/voucher.png"
                alt="voucher podarunkowy na tatuaż w studiu Nemezink"
                quality={50}
                css={css`
              width: 25vw;
              filter: drop-shadow(10px 10px 30px rgba(0, 0, 0, 0.75));
            `}
              />
            </Link>
            <div tw="flex flex-row gap-4 items-center">
              <StaticImage
                src="../images/arrow.webp"
                alt="ilustracja strzałki"
                css={css`
                      width: 100px;
                      height: auto;
                    @media (max-width: 1400px) {
                      width: 50px;
                      height: auto;
                    }
                    @media (max-width: 1024px) {
                      width: 95px;
                      height: auto;
                    }
                    @media (max-width: 576px) {
                      width: 70px;
                      height: auto;
                    }
          `}
              />
              <VoucherButton tw="mt-4">
                <Link to="/contact">
                  <Trans>voucherButton</Trans>
                </Link>
              </VoucherButton>
            </div>
          </div>
        </div>
        <SectionDivider />
        <IgSection>
          <motion.div style={{ y: yOffset }} transition={{ duration: 1 }}>
            <StaticImage
              src="../images/phoneFrame.png"
              quality="60"
              alt="profil na instagramie studia tatuażu w krakowie nemezink"
              css={css`
                width: 18.5vw;
                filter: drop-shadow(10px -5px 30px rgba(0, 0, 0, 0.75));
                margin: 3.5vw 4vw -15vw 4vw;
                @media (max-width: 1024px) {
                  min-width: 47vw;
                  margin: 0vw 3vw -40vw -4vw;
                }
              `}
            />
          </motion.div>
          <IgRight>
            <IgHeader>
              <Trans>igHeader</Trans>
              <br />
              <span style={{ fontFamily: "Merriweather" }}><Trans>igHeaderSub</Trans></span>
            </IgHeader>
            <IgText>
              <Trans>igDesc</Trans>
            </IgText>
            <a
              href="https://www.instagram.com/nemezink/"
              alt="nemezink instagram"
              rel="nofollow"
            >
              <IgButton>
                <IgIcon />
                <span><Trans>igButton</Trans></span>
              </IgButton>
            </a>
          </IgRight>
        </IgSection>
      </PromoSection>
      <MobilePromoSection>
        <VoucherHeader>
          <Trans>voucherHeader</Trans>
          <br />
          <span style={{ fontFamily: "Merriweather" }}><Trans>voucherHeaderSub</Trans></span>
        </VoucherHeader>
        <MobileText>
          <Trans>voucherDesc</Trans>
        </MobileText>
        <Link to="/contact">
          <StaticImage
            src="../images/voucher.png"
            alt="voucher podarunkowy na tatuaż w studiu Nemezink"
            quality={50}
            css={css`
            align-self: center;
            width: 25vw;
            filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
            align-self: center;
            @media (max-width: 1024px) {
              width: 75vw;
            }
            @media (max-width: 760px) {
              width: 100%;
            }
          `}
          />
        </Link>
        <div
          tw="self-center flex flex-row gap-2 mt-2"
          style={{
            transform: "translateX(-25px)",
          }}
        >
          <StaticImage
            src="../images/arrow.webp"
            alt=""
            css={css`
            @media (max-width: 1024px) {
              width: 95px;
              height: auto;
            }
            @media (max-width: 576px) {
              width: 70px;
              height: auto;
            }
          `}
          />
          <VoucherButton tw="self-center mt-4">
            <Link to="/contact">
              <Trans>voucherButton</Trans>
            </Link>
          </VoucherButton>
        </div>
        <MobileDivider />
        <div tw="flex flex-row justify-between items-center">
          <div tw="relative flex flex-col justify-center items-start z-10">
            <IgHeader>
              <Trans>igHeader</Trans> <br />
              <span style={{ fontFamily: "Merriweather" }}><Trans>igHeaderSub</Trans></span>
            </IgHeader>
            <IgText>
              <Trans>igDesc</Trans>
            </IgText>
            <a
              href="https://www.instagram.com/nemezink/"
              alt="nemezink instagram"
              rel="nofollow"
            >
              <IgButton>
                <IgIcon />
                <span><Trans>igButton</Trans></span>
              </IgButton>
            </a>
          </div>
          <StaticImage
            src="../images/phoneFrame.png"
            quality="80"
            alt="profil na instagramie studia tatuażu w krakowie nemezink"
            css={css`
              filter: drop-shadow(10px -5px 30px rgba(0, 0, 0, 0.75));
              @media (max-width: 1024px) {
                min-width: 31vw;
                width: 31vw;
              }
              @media (max-width: 760px) {
                min-width: 37vw;
                width: 37vw;
              }
            `}
          />
        </div>
      </MobilePromoSection>
      <NewsSection>
        <SectionHeader tag={<Trans>newsTag</Trans>} text={<Trans>newsHeader</Trans>}
        />
        <NewsContainer>
          <Swiper
            slidesPerView={isMobile ? 1.2 : 3.5}
            spaceBetween={isMobile ? 20 : 30}
            centeredSlides={false}
            css={css`
              width: 100%;
            `}
          >
            {postData.map(({ date, title, imgSrc, link, isMobile }) => (
              <SwiperSlide
              >
                <NewsCard
                  date={date}
                  description={title}
                  imgSrc={imgSrc}
                  link={link}
                >
                </NewsCard>
              </SwiperSlide>
            ))}
          </Swiper>
        </NewsContainer>
      </NewsSection>
      <SeoSection>
        <SectionHeader
          tag={''}
          text={<Trans>seoHeader1</Trans>}
          emphasizedText={''}
          emphasizedTag="h2"
          size="sm"
        />
        <p><Trans>seoText1</Trans></p>
        <CollapsibleSection isSeoOpen={isSeoOpen}>
          <h3><Trans>seoHeader2</Trans></h3>
          <p><Trans>seoText2</Trans></p>
          <h2><Trans>seoHeader15</Trans></h2>
          <h3><Trans>seoHeader3</Trans></h3>
          <p><Trans>seoText3</Trans></p>
          <h3><Trans>seoHeader4</Trans></h3>
          <p><Trans>seoText4</Trans></p>
          <h3><Trans>seoHeader5</Trans></h3>
          <p><Trans>seoText5</Trans></p>
          <h3><Trans>seoHeader6</Trans></h3>
          <p><Trans>seoText6</Trans></p>
          <h3><Trans>seoHeader7</Trans></h3>
          <p><Trans>seoText7</Trans></p>
          <h3><Trans>seoHeader8</Trans></h3>
          <p><Trans>seoText8</Trans></p>
          <h3><Trans>seoHeader9</Trans></h3>
          <p><Trans>seoText9</Trans></p>
          <h3><Trans>seoHeader10</Trans></h3>
          <p><Trans>seoText10</Trans></p>
          <h3><Trans>seoHeader11</Trans></h3>
          <p><Trans>seoText11</Trans></p>
          <h3><Trans>seoHeader12</Trans></h3>
          <p><Trans>seoText12</Trans></p>
          <h3><Trans>seoHeader13</Trans></h3>
          <p><Trans>seoText13</Trans></p>
          <h3><Trans>seoHeader14</Trans></h3>
          <p><Trans>seoText14</Trans></p>
        </CollapsibleSection>
        <SeoButton  onClick={toggleOpen}>
          {isSeoOpen ?
            <Trans>seoButtonExpanded</Trans> :
            <Trans>seoButtonCollapsed</Trans>
          }
        </SeoButton>
      </SeoSection>
    </Layout>
  )
}

export default App


const StudioValue = ({ index, value, description, animationDirection }) => {
  return (
    <InView rootMargin="-20%" triggerOnce={true}>
      {({ inView, ref }) => (
        <ValueContainer
          ref={ref}
          animate={{
            x: inView ? "0vw" : animationDirection,
            opacity: inView ? 1 : 0,
          }}
          transition={{ duration: 0.5 }}
        >
          <AboutUsIndex>{index}</AboutUsIndex>
          <AboutUsValue>{value}</AboutUsValue>
          <ValueDescription>{description}</ValueDescription>
        </ValueContainer>
      )}
    </InView>
  )
}

const NewsCard = ({ date, description, link, imgSrc }) => {
  return (
    <InView rootMargin="20% 0%" triggerOnce={true}>
      {({ ref, inView }) => (
        <a href={link} alt="Go to blog">
          <Card
            ref={ref}
            animate={{
              y: inView ? "0%" : "50%",
              opacity: inView ? 1 : 0,
            }}
            transition={{ duration: 0.6 }}
          >
            {imgSrc ? <img
              src={imgSrc}
              alt={description}
              css={css`
                height: 23vw;
                width: 100%;
                object-fit: cover;
                @media (max-width: 1024px) {
                  height: 70vw;
                  max-height: 70vw;
                }
            `}
            /> : <></>}
            <CardDescription>
              <span tw="p-4">{description}</span>
              <div tw="flex flex-row items-center w-full justify-between px-4 box-border"
                css={css`
                border-bottom: 2px solid #e7e7e7;
              `}
              >
                <CardDate>{date}</CardDate>
                <CardDate
                  css={css`
                  :hover {
                    text-decoration: underline;
                  }
                `}
                >{window.location.pathname === "/en/" ? 'Read More' : 'Czytaj Więcej'}</CardDate>
              </div>
            </CardDescription>
          </Card>
        </a>
      )}
    </InView>
  )
}

const Gallery = () => {
  const galleryContent = [
    {
      value: (<Trans>studioFirstValue</Trans>),
      description: (<Trans>studioFirstDesc</Trans>),
      img: (
        <StaticImage
          src="../images/AboutUsAtmosphere.jpg"
          alt="dwie uśmiechnięte dziewczyny w salonie tatuażu w Krakowie Nemezink"
          quality={20}
          css={css`
            max-height: 85vw;
            @media (max-width: 760px) {
              width: 100%;
              height: auto;
              max-height: 28rem;
            }
          `}
        />
      ),
    },
    {
      value: (<Trans>studioSecondValue</Trans>),
      description: (<Trans>studioSecondDesc</Trans>),
      img: (
        <StaticImage
          src="../images/aboutUsCreativity.jpg"
          alt="skończony tatuaż na przedramieniu wykonany w studiu tatuażu w Krakowie "
          css={css`
            max-height: 85vw;
            @media (max-width: 760px) {
              width: 100%;
              height: auto;
              max-height: 28rem;
            }
          `}
        />
      ),
    },
    {
      value: (<Trans>studioThirdValue</Trans>),
      description: (<Trans>studioThirdDesc</Trans>),
      img: (
        <StaticImage
          src="../images/aboutUsPro.jpg"
          quality={50}
          alt="tatuażystka wykonuje tatuaż klientowi w krakowskim salonie tatuażu Nemezink"
          css={css`
            max-height: 85vw;
            @media (max-width: 760px) {
              width: 100%;
              height: auto;
              max-height: 28rem;
            }
          `}
        />
      ),
    }
  ]

  const GalleryItem = ({ value, description, children }) => {
    return (
      <div style={{ position: "relative", marginBottom: "8vw" }}>
        {children}
        <GalleryDescription>
          <MobileSubtitle style={{ marginBottom: "3vw" }}>
            {value}
          </MobileSubtitle>
          <MobileText style={{ margin: "0px" }}>{description}</MobileText>
        </GalleryDescription>
      </div>
    )
  }

  return (
    <StyledSwiper
      scrollbar={{ draggable: true, hide: false, dragSize: 100 }}
    >
      {galleryContent.map(item => (
        <SwiperSlide>
          <GalleryItem
            value={item.value}
            description={item.description}
            css={css`
              max-width: 100%;
            `}
          >
            {item.img}
          </GalleryItem>
        </SwiperSlide>
      ))}
    </StyledSwiper>
  )
}

const StyledSwiper = styled(Swiper)`
  ${tw`relative`}
  width: 90%;
  @media (max-width: 760px) {
    width: 100%;
    max-height: none;
  }
`

const GalleryDescription = styled.div`
  ${tw`absolute bottom-0 left-0 w-full flex flex-col justify-end items-start`}
  box-sizing:border-box;
  min-height: 50%;
  max-width: 100%;
  padding: 2vw;
  text-align: bottom;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.12) 6.5%,
    rgba(0, 0, 0, 0.25) 12.5%,
    rgba(0, 0, 0, 0.6) 25.52%,
    rgba(0, 0, 0, 0.75) 37.5%,
    rgba(0, 0, 0, 0.8) 60.42%,
    #000000 92.19%
  );
  @media (max-width: 760px) {
    padding: 4vw;
  }
`

const MobileSubtitle = styled.h6`
  font-family: "Merriweather";
  color: #e7e7e7;
  font-size: 3vw;
  @media (max-width: 760px) {
    font-size: 4.4vw;
  }
`

const MobileText = styled.p`
  max-width: 100%;
  font-family: "Space Mono";
  color: #e7e7e7;
  font-size: 2.5vw;
  margin: 1.5vw 0vw 8vw 0vw;
  @media (max-width: 760px) {
    font-size: 3.5vw;
    margin: 2vw 0vw 10vw 0vw;
  }
`

const Card = styled(motion.div)`
  ${tw`flex flex-col items-center w-full cursor-pointer`}
  background: rgba(17, 17, 17, 0.8);
  width: 100%;
  @media (max-width: 1024px) {
  }
`
const CardDescription = styled.div`
  ${tw`flex flex-col justify-between w-full`}
  color: #e7e7e7;
  font-size: 1vw;
  max-height: 8vw;
  width: 100%;
  height: 8vw;
  @media (max-width: 1024px) {
    padding: 2.6vw;
    font-size: 3.4vw;
    height: 29vw;
    max-height: 29vw;
    font-size: 3.5vw;
  }
`

const CardDate = styled.div`
  font-family: "Roboto";
  color: #e7e7e7;
  font-size: 1vw;
  font-weight: 500;
  @media (max-width: 1024px) {
    font-size: 3.5vw;
  }
`

const HeroScreen = styled.div`
  ${tw`relative float-left h-full w-full m-0 p-0 overflow-hidden bg-fixed bg-cover `}
  ${tw`flex flex-col justify-center items-center `}
  background-image: url(${HeroBg});
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  color: #ffffff;
  font-size: 10vw;
  font-weight: 700;
  font-style: normal;
  text-align: center;
  gap: 3rem;
    height: 100vh;
    max-height: 100vh;
  @media (max-width: 1024px) {
    gap: 2rem;
    padding-top: 20vw;
    background-attachment: initial;
  }
  @media (max-width: 767px) {
    gap: 1rem;
    padding-top: 20vw;
    background-attachment: initial;
  }
`

const HeroText = styled(motion.img)`
  ${tw`absolute m-auto`}
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  z-index: 2;
  max-width: 100%;
  width: 100%;
  @media (max-width: 1024px) {
  }
`

const HeroHeader = styled.h1`
  @media (max-width: 1024px) {
    font-size: 24px;
  }
`

const AboutUsSection = styled.div`
  ${tw`relative float-left w-full overflow-hidden bg-fixed`}
  ${tw`flex flex-row justify-between`}
  background-image: url(${AboutUsBg});
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 6vw 8vw 6vw 7vw;
  @media (max-width: 1024px) {
    ${tw`flex-col`}
    padding: 7.5vw 5vw 7.5vw 5vw;
    background-attachment: initial;
  }
  @media (max-width: 760px) {
    ${tw`flex-col`}
    padding: 10vw 4vw 10vw 4vw;
    background-attachment: initial;
  }
`

const AboutUsLeft = styled.div`
  ${tw`relative flex flex-col`}
  @media (max-width: 1024px) {
    ${tw`flex-col`}
    display:none;
  }
`
const AboutUsRight = styled.div`
  ${tw`relative flex flex-col`}
  @media (max-width: 1024px) {
    ${tw`flex-col`}
    display:none;
  }
`

const MobileAboutUs = styled.div`
  position: relative;
  display: none;
  @media (max-width: 1024px) {
    ${tw`flex flex-col w-full`}
    width:100%;
  }
`

const MobileDivider = styled.div`
  ${tw`w-full`}
  height: 1px;
  background-color: #e7e7e7;
  @media (max-width: 1024px) {
    margin: 7vw 0vw 5vw 0vw;
  }
  @media (max-width: 760px) {
    margin: 10vw 0vw;
  }
`

const ValueContainer = styled(motion.div)`
  ${tw`flex flex-col`}
  margin: 4vw 0vw 4.5vw 2.8vw;
  @media (max-width: 1024px) {
    margin: 0px;
  }
`

const AboutUsIndex = styled.span`
  align-self: flex-start;
  border-bottom: 2px solid #e7e7e7;
  font-family: "Merriweather";
  color: #e7e7e7;
  font-size: 6.6vw;
  line-height: 1.05;
  margin-bottom: 2.5vw;
  @media (max-width: 1024px) {
    border: none;
    font-size: 13vw;
    line-height: auto;
    margin-bottom: 4.5vw;
  }
`

const AboutUsValue = styled.span`
  font-family: "Merriweather";
  font-size: 1.66vw;
  color: #e7e7e7;
  letter-spacing: 0.05em;
  margin: 0.3vw 0vw;
  @media (max-width: 1024px) {
    font-size: 4.3vw;
    margin: 0vw 0vw 3.6vw 0vw;
  }
`

const DesktopText = styled.p`
  font-family: "Space Mono";
  font-size: 1.1vw;
  color: #e7e7e7;
  max-width: 31vw;
  line-height: 168%;
`

const ValueDescription = styled.span`
  font-family: "Space Mono";
  font-size: 1.1vw;
  color: #e7e7e7;
  max-width: 28vw;
  line-height: 168%;
  @media (max-width: 1024px) {
    font-size: 3.4vw;
    max-width: none;
    line-height: auto;
  }
`

const HeroDetails = styled.span`
  ${tw`flex flex-row items-center transition hover:underline cursor-pointer absolute uppercase`}
  bottom: 2vw;
  left: 2vw;
  color: #fbfbfb;
  font-size: 1.05vw;
  font-family: "Space Mono";
  font-weight: bold;
  z-index: 1;
  @media (max-width: 1024px) {
    font-size: 3vw;
  }
  @media (max-width: 760px) {
    font-size: 3.5vw;
  }
`

const HeroButton = styled.button`
  ${tw`z-20 flex flex-row items-center gap-2`}
  border: solid 2px #e7e7e7;
  padding: 12px 12px 8px 16px;
  font-size: 18px;
  line-height: 1;
  transition: all 0.3s ease-out;
  letter-spacing: 2px;
  :hover {
    color: #171717;
    background-color: #e7e7e7;
  }
  @media (max-width: 1024px) {
    ${tw`gap-1`}
    white-space: nowrap;
    min-width: fit-content;
    width: fit-content;
    font-size: 20px;
    padding: 10px 10px 8px 15px;
  }
  @media (max-width: 768px) {
    font-size: 18px;
    padding: 8px 8px 6px 12px;
  }
`

const SeoButton = styled.button`
  ${tw`z-20 flex flex-row items-center`}
  align-self: center;
  border: solid 2px #e7e7e7;
  font-size: 18px;
  transition: all 0.3s ease-out;
  letter-spacing: 2px;
    white-space: nowrap;
    min-width: fit-content;
    width: fit-content;
    font-size: 20px;
    padding: 10px 10px 8px 15px;
  :hover {
    color: #171717;
    background-color: #e7e7e7;
  }
`

const VoucherButton = styled.button`
  ${tw` z-20 flex flex-row items-center gap-2 justify-center`}
  border: solid 2px #e7e7e7;
  font-family: "Bebas Neue";
  line-height: 1;
  transition: all 0.3s ease-out;
  letter-spacing: 2px;
  color: #e7e7e7;
  width: 10.1vw;
  height: 3.3vw;
  font-size: 1.1vw;
  :hover {
    color: #171717;
    background-color: #e7e7e7;
  }
  @media (max-width: 1024px) {
    margin-top: 30px;
    font-size: 2.5vw;
    width: 25vw;
    height: 8vw;
  }
  @media (max-width: 760px) {
    font-size: 3.7vw;
    width: 32vw;
    height: 10.5vw;
  }
`

const PromoSection = styled.div`
  ${tw`relative float-left w-full overflow-hidden bg-fixed`}
  ${tw`flex flex-col`}
  line-height: 1.05 !important;
  padding: 6vw 8vw 4vw 11vw;
  background-color: #171717;
  @media (max-width: 1024px) {
    display: none;
  }
`

const SeoSection = styled.div`
  ${tw`flex flex-col`}
  padding: 6vw 8vw 4vw 11vw;
  color: #fbfbfb;
  background-color: #171717;
`

const MobilePromoSection = styled.div`
  overflow: hidden;
  position: relative;
  display: none;
  @media (max-width: 1024px) {
    ${tw`flex flex-col w-full`}
    padding: 10vw 4vw 10vw 4vw;
    background-color: #171717;
  }
`

const PromoText = styled.span`
  font-family: "Space Mono";
  font-size: 1.1vw;
  color: #e7e7e7;
  width: 32vw;
  max-width: 32vw;
  line-height: 168%;
  margin: 0.5vw 0vw;
  @media (max-width: 1024px) {
    margin: 8vw 0vw;
    width: 100%;
    line-height: auto;
    font-size: 3.5vw;
  }
`

const PromoCTA = styled.div`
  ${tw`flex flex-row items-center`}
  transform:  translateX(-6vw);
`

const ContactButton = styled(Link)`
  ${tw`flex justify-center items-center`}
  font-family: "Merriweather";
  font-size: 1vw;
  min-width: 10.9vw;
  margin: 6vw 1vw;
  min-height: 3.6vw;
  border: 2px solid #e7e7e7;
  color: #e7e7e7;
  transition: all 0.4s ease-out;
  :hover {
    color: #171717;
    background-color: #e7e7e7;
  }
  @media (max-width: 1024px) {
    font-size: 2.5vw;
    width: 25vw;
    height: 9vw;
    margin: 3vw 1.5vw;
  }
  @media (max-width: 760px) {
    margin: 4vw 2vw;
    width: 34vw;
    height: 12vw;
    font-size: 3.2vw;
  }
`

const SectionDivider = styled.div`
  ${tw`w-full`}
  height: 2px;
  background-color: #e7e7e7;
  margin-top: 5vw;
`

const VoucherContact = styled(Link)`
  ${tw`float-right self-end`}
  font-family: "Roboto";
  color: #e7e7e7;
  font-weight: 500;
  margin-top: 0.5vw;
  margin-bottom: 2.5vw;
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

const VoucherHeader = styled.span`
  font-size: 4.5vw;
  font-family: "Roboto";
  font-weight: 500;
  color: #e7e7e7;
  line-height: 110%;
  @media (max-width: 1024px) {
    font-size: 7vw;
    line-height: 115%;
    margin-bottom: 5vw;
  }
  @media (max-width: 760px) {
    font-size: 10.5vw;
    line-height: 115%;
    margin-bottom: 8vw;
  }
`

const VoucherSubheader = styled.span`
  font-family: "Roboto";
  text-decoration: uppercase;
  font-size: 1.25vw;
  font-weight: 500;
  color: #e7e7e7;
  margin-bottom: 0.5vw;
`

const VoucherText = styled.span`
  font-family: "Space Mono";
  font-size: 1vw;
  color: #e7e7e7;
  width: 25vw;
  max-width: 25vw;
  line-height: 160%;
  @media (max-width: 1024px) {
    width: 100%;
    font-size: 4vw;
    line-height: auto;
    margin-bottom: 6vw;
  }
`

const IgSection = styled.div`
  ${tw`relative flex flex-row justify-center`}
`

const IgRight = styled.div`
  ${tw`flex flex-col items-start`}
  margin: 5vw 0vw 0vw 4vw;
`

const IgText = styled.span`
  font-family: "Space Mono";
  font-size: 1.1vw;
  color: #e7e7e7;
  max-width: 30vw;
  margin: 1.5vw 0vw 2vw 0vw;
  @media (max-width: 1024px) {
    max-width: none;
    color: #e7e7e7;
    line-height: 160%;
    margin: 2.5vw 4vw 5vw 0vw;
    font-size: 2.5vw;
  }
  @media (max-width: 760px) {
    max-width: none;
    margin-top: 2vw;
    font-size: 3.5vw;
    line-height: auto;
    margin-bottom: 6vw;
  }
`

const IgHeader = styled.span`
  font-size: 4.4vw;
  font-family: "Roboto";
  font-weight: 500;
  color: #e7e7e7;
  line-height: 110%;
  @media (max-width: 1024px) {
    font-size: 7vw;
    line-height: auto;
    margin-bottom: 4vw;
  }
  @media (max-width: 760px) {
    font-size: 10.5vw;
    line-height: auto;
    margin-bottom: 6vw;
  }
`

const IgIcon = styled(FaInstagram)`
  font-size: 1.4vw;
  margin-right: 0.5vw;
  @media (max-width: 1024px) {
    font-size: 4vw;
  }
`

const IgButton = styled.button`
  ${tw`flex flex-row items-center justify-center box-content`}
  width: 10.1vw;
  height: 3.3vw;
  color: #e7e7e7;
  font-size: 1.1vw;
  font-family: "Merriweather";
  font-weight: 400;
  border: 2px solid #e7e7e7;
  transition: all 0.4s ease-out;
  :hover {
    color: #171717;
    background-color: #e7e7e7;
  }
  @media (max-width: 1024px) {
    font-size: 2.5vw;
    width: 25vw;
    height: 8vw;
  }
  @media (max-width: 760px) {
    font-size: 3.7vw;
    width: 32vw;
    height: 10.5vw;
  }
`

const NewsSection = styled.div`
  ${tw`flex flex-col bg-fixed box-content`}
  padding: 6vw 8vw 4vw 11vw;
  background-image: url(${newsBg});
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 1024px) {
    padding: 9vw 4vw 9vw 4vw;
    background-attachment: initial;
  }
`

const NewsContainer = styled(motion.div)`
  ${tw`flex flex-row justify-between items-center`}
  width: calc(100% + 8vw);
  @media(max-width:1024px) {
    ${tw`flex-col justify-center items-center`}
    width: auto;
    margin-top: 2vw;
    max-width: 100%
  }
`

const RightArrow = styled(BsArrowRightShort)`
  font-size: 38px;
  @media (max-width: 1024px) {
  font-size: 24px;
  }
`

const breakpoints = {
  xs: '480px',
  sm: '768px',
  md: '1024px',
  lg: '1920px',
};

const Video = styled.video`
  max-width: 60vw;

  @media (min-width: ${breakpoints.xs}) {
    max-width: 50vw;
  }

  @media (min-width: ${breakpoints.sm}) {
    max-width: 60vw;
  }

  @media (min-width: ${breakpoints.md}) {
    max-width: 50vw;
  }

  @media (min-width: ${breakpoints.lg}) {
    max-width: 50vw;
    /* Styles for large (lg) devices (desktops, 1920px and up) */
  }
`;

const CollapsibleSection = styled.div`
${tw`flex flex-col`}
overflow: hidden;
transition: max-height 2s cubic-bezier(0.25, 0.1, 0.25, 1);
 ${({ isSeoOpen }) => (isSeoOpen ? "max-height: 3000px" : "max-height:0px")};
style={{ maxHeight: isSeoOpen ? '3500px' : '0', }}
@media (max-width: 1024px) {
 ${({ isSeoOpen }) => (isSeoOpen ? "max-height: 5000px" : "max-height:0px")};
}
@media (max-width: 768px) {
 ${({ isSeoOpen }) => (isSeoOpen ? "max-height: 6000px" : "max-height:0px")};
}
`